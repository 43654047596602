import Container from 'components/layout/Container/Container';
import Footer from 'components/layout/Footer/Footer';
import Header from 'components/layout/Header/Header';
import React from 'react';
import RootLayout from 'components/layout/RootLayout/RootLayout';

import ghostChat from './Mixily_Illos_1-02.svg';
import handRsvp from './Mixily_Illos_1-04.svg';
import wormCal from './Mixily_Illos_1-03.svg';

import { Button, Link } from 'components/common';
import { reverse } from 'shared/shared/routing/mixily-routes';
import clubhouse from './clubhouse.png';
import origami from './origami.png';
import tickets from './tickets.png';

import Testimonials from 'components/pages/LandingPage2/Testimonials/Testimonials3';
import UseCases from 'components/pages/LandingPage2/UseCases/UseCases';

import MiniFeature from 'components/pages/LandingPage2/MiniFeature';
import hands from './hands.svg';

const newOrange = '#EF9071';
const newGray = '#665F5D';

const LandingPage = () => {
  return (
    <RootLayout title="Mixily">
      <div className="py-3 text-center text-white" style={{ backgroundColor: newOrange }}>
        <a
          href="https://www.producthunt.com/posts/virtual-venue-by-mixily"
          rel="noreferrer"
          target="_blank"
          className="font-bold text-white"
        >
          🎉 #1 Product of the day on Product Hunt 🎉
        </a>
      </div>

      <Header landingPage={true} withSidebar={false} usePermanentSidebar={false} />
      <Container>
        <div className="mt-8 text-2xl text-center lg:font-light md:text-5xl lg:text-6xl">
          Kind of like Facebook Events.
          <br />
          But not on Facebook.
          <br />
          And better.
        </div>

        <div className="mt-16">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
            <MiniFeature
              title="Beautiful event landing pages"
              imgSrc={handRsvp}
              subText="Schedule invitations, reminders, and messages"
            />
            <MiniFeature
              title="Guest lists, RSVPs, and ticketing"
              imgSrc={wormCal}
              subText="With suggestion donations and promo codes"
            />
            <MiniFeature
              title="No signup for your guests"
              imgSrc={ghostChat}
              subText="No ads, no spam, and your guests don't need an account."
            />
          </div>
        </div>

        <div className="my-20 text-center">
          <Button
            color="custom"
            href={reverse('account_signup')}
            className="px-6 py-2 mx-auto text-xl font-extrabold text-white uppercase rounded-md focus:outline-none md:text-2xl lg:text-3xl"
            label="Sign up for free"
            style={{ backgroundColor: '#ef8f71' }}
          />
        </div>
      </Container>

      <div style={{ backgroundColor: newGray }} className="text-white">
        <Container>
          <div className="pt-20 text-5xl font-bold text-center">Gather RSVPs or sell tickets</div>

          <div className="flex flex-col items-center justify-center mt-20 lg:flex-row">
            <div className="max-w-2xl">
              <img src={tickets} alt="Example ticket sales configuration" />
            </div>

            <div className="max-w-2xl mt-16 lg:ml-16 lg:mt-0">
              <div>
                <ul className="text-xl">
                  <li>Create ticketing tiers</li>
                  <li className="mt-4">Connect your bank account with Stripe</li>
                  <li className="mt-4">Create promo codes</li>
                  <li className="mt-4">Get payouts before your event</li>
                  <li className="mt-4">Track your ticket sales across events with the Revenue Dashboard</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="mt-20">&nbsp;</div>
        </Container>
      </div>

      <div>
        <Container>
          <div className="pt-20 text-5xl font-bold text-center">Beautiful event landing pages</div>

          <div className="flex flex-col items-center justify-center mt-20 lg:flex-row">
            <div className="max-w-2xl">
              <img src={origami} alt="Example event landing page" />
            </div>

            <div className="max-w-2xl mt-16 lg:ml-16 lg:mt-0">
              <div>
                <ul className="text-xl">
                  <li>Upload an event image and fill in the time, place, and details.</li>
                  <li className="mt-4">
                    From one screen, schedule messages, embed gifs, and poll your guests for a date.
                  </li>
                  <li className="mt-4">Save contact lists for recurring events.</li>
                  <li className="mt-4">Embed an event calendar on your website.</li>
                </ul>
              </div>

              <div className="mt-16">
                <div>
                  <Button
                    color="custom"
                    href={reverse('event_create')}
                    className="px-6 py-2 text-xl font-extrabold text-white uppercase rounded-md focus:outline-none md:text-2xl lg:text-3xl "
                    label="Create a test event"
                    style={{ backgroundColor: newGray }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-20">&nbsp;</div>
        </Container>
      </div>

      <div style={{ backgroundColor: newGray }} className="text-white">
        <Container>
          <div className="pt-20 text-5xl font-bold text-center">Custom-branded video calls</div>

          <div className="flex flex-col items-center justify-center mt-20 lg:flex-row">
            <div className="max-w-2xl">
              <img src={clubhouse} alt="Example virtual venue" />
            </div>

            <div className="max-w-2xl mt-16 lg:ml-16 lg:mt-0">
              <div>
                <ul className="text-xl">
                  <li>Create your own branded virtual event space with custom colors and your logo.</li>
                  <li className="mt-4">
                    Simple and thoughtful features like expandable text chat, and screen sharing.
                  </li>
                  <li className="mt-4">Embed your venue right into your website, or host your venue on Mixily.</li>
                  <li className="mt-4">Direct your guests to actions on the rest of your site.</li>
                </ul>
              </div>

              <div className="mt-16">
                <div>
                  <Button
                    color="custom"
                    href={reverse('virtual_venue_landing_page')}
                    className="px-6 py-2 text-xl font-extrabold text-white uppercase rounded-md focus:outline-none md:text-2xl lg:text-3xl "
                    label="Learn more"
                    style={{ backgroundColor: '#FDFBF8', color: newGray }}
                  />
                </div>
                <div className="mt-4">
                  <Link href={reverse('virtual_venue_demo')} className="text-lg text-white underline">
                    or try the theme editor
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-20">&nbsp;</div>
        </Container>
      </div>
      <UseCases />

      <Testimonials />

      <div className="pt-40 text-center">
        <img src={hands} alt="Abstract line drawing of clasped hands" className="mx-auto" />
        <div className="my-20 text-center">
          <Button
            color="custom"
            href={reverse('account_signup')}
            className="px-6 py-2 mx-auto text-xl font-extrabold text-white uppercase rounded-md focus:outline-none md:text-2xl lg:text-3xl"
            label="Sign up for free"
            style={{ backgroundColor: '#ef8f71' }}
          />
        </div>
      </div>
      <Footer />
    </RootLayout>
  );
};

export default LandingPage;
