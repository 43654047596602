// To add a new route, see https://www.notion.so/mixily/How-to-add-a-new-route-URL-path-3a25de00890045e4be757cdc87079dca

import Reverser from './Reverser';

// NB route-parser lib doesn't support regex specifications in reversing, e.g. ([0-9]+)

// You must also edit RouteNameToComponent
export const RouteNameToPath = {
  about: '/about',
  account_login: '/account/login',
  account_settings: '/account/settings',
  account_signup: '/account/signup',
  account_authorize: '/account/authorize',
  connect_account: '/connect-account',
  connect_zoom: '/connect-zoom',
  dashboard_contacts: '/dashboard/contacts',
  dashboard_event: '/dashboard/event/:id',
  dashboard_events: '/dashboard/events',
  email_token: '/account/login/email-token/:token',
  event_calendar: '/event/calendar',
  event_create: '/event/create',
  event_duplicate: '/event/:id/copy',
  event_check_password: '/event/:id',
  event_read: '/event/:id',
  event_update: '/event/:id/edit',
  event_add_calendar: '/event/:id/add-calendar',
  event_proxy: '/l/:id',
  faq: '/faq',
  virtual_venue_landing_page: '/features/virtualvenue',
  virtual_venue_landing_page2: '/features/virtualvenue2',
  features_detail_venue: '/features/virtual-venue',
  features_detail: '/features/:id',
  features: '/features',
  home: '/',
  invite_read: '/invite/:id(?rsvp=:rsvp)',
  invite: '/invite-magic-link/:token',
  list: '/lists/:id',
  listing_edit: '/listing/:id/edit',
  listing_embed: '/embed/listing/:id',
  listing_index: '/listing',
  listing_read: '/listing/:id',
  lists: '/lists',
  login_as: '/account/login_as',
  order_read: '/order/:id',
  pricing: '/pricing',
  resend_confirmation: '/account/resend-confirmation',
  reset_password_token: '/account/reset-password/:token',
  reset_password: '/account/reset-password',
  run_read: '/run/:id',
  site_privacy: '/site/privacy',
  site_support: '/site/support',
  site: '/site/:id',
  size_zoom_support: '/site/support/zoom',
  ticketing_embed: '/embed/ticketing/:id',
  verify_email: '/account/verify-email/:email/:token',
  venue: '/v/:id',
  venue_embed: '/embed/venue/:id',
  virtual_venue_create: '/venue/new',
  virtual_venue_detail: '/venue/:id/details',
  virtual_venue_demo: '/venue/demo',
  virtual_venue_edit: '/venue/:id/edit',
  virtual_venue_list: '/venue',
  virtual_venue_plans: '/venue/plans',
  virtual_venue_recordings: '/venue/:id/recordings',
  virtual_venue_recording: '/venue/:venueId/recordings/:recordingId',
  virtual_venue_chat_history: '/venue/:id/chat-history',
  virtual_venue_guest_log: '/venue/:id/guest-log',
  virtual_venue_signup: '/venue/signup',
  virtual_venue_subscribe: '/venue/subscribe(/:id)',
  virtual_venue_success: '/venue/success',
  virtual_venue_attend: '/venue/:slugOrId', // Venue page on mixily.com
  virtual_venue_embed: '/venue/:id/embed', // iframe URL
};

// These are rendered on the server, but the frontend may want to reverse them
const ServerRouteNameToPath = {
  add_calendar: '/cal/event/:id',
  ics_from_invite: '/gen/ics/:id/invite/:inviteId',
  ics_from_order: '/gen/ics/:id/order/:orderId',
  ics_from_role_invite: '/gen/ics/:id/role_invite/:roleInviteId',
  script_iframe: '/scripts/mixily-iframe.min.js',
  script_embed: '/scripts/mixily-embed.min.js',
  virtual_venue_embed_js: '/gen/venue/:id/embed.js', // parent frame JS
};

type FrontendRouteName = keyof typeof RouteNameToPath;
type ServerRouteName = keyof typeof ServerRouteNameToPath;
export type RouteName = FrontendRouteName | ServerRouteName;

const reverser = new Reverser({ ...RouteNameToPath, ...ServerRouteNameToPath });
export const reverse = reverser.reverse;
