import { Button } from 'components/common';
import { constructQueryString } from 'shared/shared/utils';
import { reverse } from 'shared/shared/routing/mixily-routes';
import React, { ReactNode } from 'react';
import Slider from 'react-slick';

interface ItemProps {
  no?: boolean;
  children: ReactNode;
}

const Item = (props: ItemProps) => (
  <li className="relative pl-3 mt-1 text-base leading-tight sm:text-sm md:text-base lg:text-lg">
    {props.no ? (
      <div className="absolute top-0 left-0 mt-1 text-xs">x</div>
    ) : (
      <div className="absolute top-0 left-0 w-1 h-1 mt-2.5 bg-white rounded-full" />
    )}
    {props.children}
  </li>
);

interface PriceProps {
  children: ReactNode;
  subHead?: ReactNode;
}

const Price = (props: PriceProps) => (
  <div className="inline-block py-1 pl-2 pr-8 mb-6 border border-white border-solid rounded-md">
    <span className="text-lg text-white">{props.children}</span>
    {props.subHead && <span className="block text-xs text-white">{props.subHead}</span>}
  </div>
);

interface SignupButtonProps {
  href: string;
  children: string;
}

const SignupButton = (props: SignupButtonProps) => (
  <div className="mt-auto text-center">
    <Button
      color="custom"
      className="px-8 py-4 mt-12 text-lg font-extrabold text-center text-white uppercase rounded-md sm:text-xl md:text-2xl lg:text-3xl bg-default focus:outline-none"
      href={props.href}
      label={props.children}
    ></Button>
  </div>
);

const Title: React.FC = (props) => (
  <h3 className="mt-0 mb-4 text-4xl font-normal font-medium text-white sm:font-bold lg:font-normal sm:text-2xl md:text-3xl lg:text-4xl">
    {props.children}
  </h3>
);

const Price1 = () => (
  <div className="flex flex-col justify-between h-full px-8 py-12 lg:px-12 lg:py-16 xl:px-16 bg-gray">
    <div>
      <Title>Starter</Title>
      <Price>Free!</Price>
      <ul className="text-lg text-white">
        <Item>1 room</Item>
        <Item>10 participants</Item>
        <Item>1 hour call length</Item>
        <Item>Customize &amp; embed</Item>
        <Item>Chat room with emoji reactions</Item>
        <Item>Screen sharing</Item>
        <Item>Speaker view</Item>
        <Item>Host can mute participants</Item>
      </ul>
      <div className="mt-4">
        <span className="block text-lg text-white">Ticketing: Standard fees</span>
      </div>
    </div>
    <SignupButton
      href={`${reverse('virtual_venue_signup')}${constructQueryString({
        next: reverse('virtual_venue_success'),
      })}`}
    >
      Sign Up
    </SignupButton>
  </div>
);

const Price2 = () => (
  <div className="flex flex-col justify-between h-full px-8 py-12 bg-gray-100 lg:px-12 lg:py-16 xl:px-16">
    <div>
      <Title>Individual</Title>
      <Price>$10/mo</Price>
      <ul className="text-lg text-white">
        <Item>5 rooms</Item>
        <Item>50 participants</Item>
        <Item>Unlimited call length</Item>
        <Item>14 day free trial</Item>
        <Item>All features of Starter</Item>
      </ul>
      <div className="mt-4">
        <span className="block text-lg text-white">Ticketing: Standard fees</span>
      </div>
    </div>
    <SignupButton
      href={`${reverse('virtual_venue_signup')}${constructQueryString({
        next: reverse('virtual_venue_subscribe'),
      })}`}
    >
      Sign Up
    </SignupButton>
  </div>
);

const Price3 = () => (
  <div className="flex flex-col justify-between h-full px-8 py-12 lg:py-16 lg:px-12 xl:px-16 bg-black-100">
    <div>
      <Title>Community</Title>
      <Price>$30/mo</Price>
      <ul className="text-lg text-white">
        <Item>10 rooms</Item>
        <Item>200 participants</Item>
        <Item>Unlimited call length</Item>
        <Item>All features of Individual</Item>
        <Item>Exclusive access to new features</Item>
        <Item>Dedicated account manager</Item>
      </ul>
      <div className="mt-4">
        <span className="block text-lg text-white">Ticketing: Standard fees</span>
      </div>
    </div>
    <SignupButton
      href={`${reverse('virtual_venue_signup')}${constructQueryString({
        next: reverse('virtual_venue_subscribe'),
      })}`}
    >
      Sign Up
    </SignupButton>
  </div>
);

const PricingTable = () => {
  return (
    <div className="relative z-10">
      <div className="hidden sm:block">
        <div className="w-full max-w-screen-xl mx-auto">
          <div className="grid grid-cols-3">
            <Price1 />
            <Price2 />
            <Price3 />
          </div>
        </div>
      </div>
      <div className="block px-8 sm:hidden">
        <Slider
          infinite={true}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          initialSlide={0}
          arrows={false}
          dots={true}
          pauseOnDotsHover={true}
          pauseOnHover={true}
        >
          {/* Note: you can't use a wrapper component for these or Slider breaks */}
          <Price1 />
          <Price2 />
          <Price3 />
        </Slider>
      </div>
    </div>
  );
};

export default PricingTable;
