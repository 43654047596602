import { Loading } from 'components/common';
import { VirtualVenueData } from 'components/VirtualVenue/types';
import { VirtualVenueDisplayProvider } from 'components/VirtualVenue/contexts/VirtualVenueDisplayContext';
import { VirtualVenueEditProvider } from 'components/pages/VirtualVenueEditPage/VirtualVenueEditContext';
import { VirtualVenueThemeProvider, defaultTheme } from 'components/VirtualVenue/contexts/VirtualVenueThemeContext';
import { getDefaultVirtualVenueData } from 'components/VirtualVenue/utils';
import { reverse } from 'shared/shared/routing/mixily-routes';
import FeatureLockedContainer from 'components/pages/VirtualVenueCreatePage/FeatureLockedContainer';
import Link from 'components/Link';
import LoginRequired from 'components/LoginRequired';
import MinimalLayout from 'components/layout/MinimalLayout/MinimalLayout';
import React from 'react';
import VirtualVenueCreate from 'components/pages/VirtualVenueCreatePage/VirtualVenueCreate';
import VirtualVenueOuter from 'components/VirtualVenue/VirtualVenueDisplay/VirtualVenueOuter';
import useCanCreateVenue from 'utils/hooks/subscriptions/useCanCreateVenue';

const defaultData: VirtualVenueData = getDefaultVirtualVenueData();

const VirtualVenueCreatePage = () => {
  const { loading, canCreateVenue } = useCanCreateVenue(true);
  return (
    <LoginRequired>
      <MinimalLayout title="Create a new venue">
        <div>
          <div>
            <Link href={reverse('virtual_venue_list')} className="text-lg leading-none">
              back to my venues
            </Link>
          </div>

          <div className="mt-12 text-5xl leading-none">Create New Venue</div>

          <div className="mt-12">
            <VirtualVenueDisplayProvider initialSetting={{ mode: 'edit' }}>
              <VirtualVenueThemeProvider initialTheme={{ ...defaultTheme }}>
                <VirtualVenueEditProvider initialState={{ title: '', logo: '' }}>
                  <FeatureLockedContainer
                    locked={!(canCreateVenue || loading)}
                    message={'You have reached your limit of virtual venues.'}
                    cta={'Upgrade your plan to get more!'}
                  >
                    {loading ? (
                      <Loading />
                    ) : (
                      <VirtualVenueOuter virtualVenue={defaultData}>
                        <VirtualVenueCreate />
                      </VirtualVenueOuter>
                    )}
                  </FeatureLockedContainer>
                </VirtualVenueEditProvider>
              </VirtualVenueThemeProvider>
            </VirtualVenueDisplayProvider>
          </div>
        </div>
      </MinimalLayout>
    </LoginRequired>
  );
};
export default VirtualVenueCreatePage;
