// To add a new route, see https://www.notion.so/mixily/How-to-add-a-new-route-URL-path-3a25de00890045e4be757cdc87079dca

import AboutPage from 'components/pages/AboutPage';
import AccountContactListsPage from 'components/pages/AccountContactListsPage/AccountContactListsPage';
import AccountLoginAsPage from 'components/pages/AccountLoginAsPage';
import AccountLoginPage from 'components/pages/AccountLoginPage/AccountLoginPage';
import AccountSettingsPage from 'components/pages/AccountSettingsPage/AccountSettingsPage';
import AccountSignupPage from 'components/pages/AccountSignupPage/AccountSignupPage';
import AccountTokenLoginPage from 'components/pages/AccountTokenLoginPage';
import AccountVerifyEmailPage from 'components/pages/AccountVerifyEmailPage';
import AddToCalendarPage from 'components/pages/AddToCalendarPage/AddToCalendarPage';
import ContactsDashboardPage from 'components/pages/Dashboard/ContactsDashboardPage/ContactsDashboardPage';
import EmbeddedListingPage from 'components/pages/ListingPage/EmbeddedListingPage';
import EmbeddedTicketPage from 'components/pages/TicketPage/EmbeddedTicketPage';
import EmbeddedVenuePage from 'components/pages/VenuePage/EmbeddedVenuePage';
import EmbeddedVirtualVenuePage from 'components/pages/VirtualVenuePage/EmbeddedVirtualVenuePage';
import EventCalendarPage from 'components/pages/EventCalendarPage/EventCalendarPage';
import EventCheckPrivacy from 'components/pages/EventReadPage/EventReadCheckPrivacy';
import EventCreatePage from 'components/pages/EventCreatePage';
import EventDashboardPage from 'components/pages/Dashboard/EventDashboardPage/EventDashboardPage';
import EventDuplicatePage from 'components/pages/EventDuplicatePage/EventDuplicatePage';
import EventLocationProxy from 'components/pages/EventLocationProxy/EventLocationProxy';
import EventUpdatePage from 'components/pages/EventUpdatePage';
import EventsDashboardPage from 'components/pages/Dashboard/EventsDashboardPage/EventsDashboardPage';
import FaqPage from 'components/pages/FaqPage/FaqPage';
import FeaturesDetailPage from 'components/pages/FeaturesDetailPage/FeaturesDetailPage';
import FeaturesDetailVenuePage from 'components/pages/FeaturesDetailVenuePage/FeaturesDetailVenuePage';
import FeaturesPage from 'components/pages/FeaturesPage';
import IndexPage from 'components/pages/IndexPage';
import InviteMagicLinkPage from 'components/pages/InviteMagicLinkPage';
import InviteReadPage from 'components/pages/InviteReadPage';
import ListingEditPage from 'components/pages/ListingEditPage/ListingEditPage';
import ListingIndexPage from 'components/pages/ListingPage/ListingIndexPage';
import ListingPage from 'components/pages/ListingPage/ListingPage';
import OAuthAuthorizePage from 'components/pages/OAuthAuthorizePage';
import OrderReadPage from 'components/pages/OrderReadPage/OrderReadPage';
import Redirect from 'components/Redirect';
import ResendConfirmationEmailPage from 'components/pages/ResendConfirmationEmailPage/ResendEmailConfirmationPage';
import ResetPasswordPage from 'components/pages/ResetPasswordPage/ResetPasswordPage';
import ResetPasswordTokenPage from 'components/pages/ResetPasswordTokenPage';
import RunReadPage from 'components/pages/RunReadPage/RunReadPage';
import SiteFeeCalculator from 'components/pages/SiteFeeCalculator';
import SitePage from 'components/pages/SitePage/SitePage';
import SitePrivacyPage from 'components/pages/SitePrivacyPage';
import SiteSupportPage from 'components/pages/SiteSupportPage';
import SiteZoomSupportPage from 'components/pages/SiteZoomSupportPage';
import StripeResponsePage from 'components/pages/StripeResponsePage';
import TestPage from 'components/pages/TestPage/TestPage';
import VenuePage from 'components/pages/VenuePage/VenuePage';
import VirtualVenueChatHistoryPage from 'components/pages/VirtualVenueChatHistoryPage/VirtualVenueChatHistoryPage';
import VirtualVenueCreatePage from 'components/pages/VirtualVenueCreatePage/VirtualVenueCreatePage';
import VirtualVenueDetailsPage from 'components/pages/VirtualVenueDetailsPage/VirtualVenueDetailsPage';
import VirtualVenueEditPage from 'components/pages/VirtualVenueEditPage/VirtualVenueEditPage';
import VirtualVenueGuestLogPage from 'components/pages/VirtualVenueGuestLogPage/VirtualVenueGuestLogPage';
import VirtualVenueLandingPage from 'components/pages/VirtualVenueLandingPage/VirtualVenueLandingPage';
import VirtualVenueListPage from 'components/pages/VirtualVenueListPage/VirtualVenueListPage';
import VirtualVenuePage from 'components/pages/VirtualVenuePage/VirtualVenuePage';
import VirtualVenuePlansPage from 'components/pages/VirtualVenuePlansPage/VirtualVenuePlansPage';
import VirtualVenueRecordingPage from 'components/pages/VirtualVenueRecordingPage/VirtualVenueRecordingPage';
import VirtualVenueRecordingsPage from 'components/pages/VirtualVenueRecordingsPage/VirtualVenueRecordingsPage';
import VirtualVenueSignupPage from 'components/pages/VirtualVenueSignupPage/VirtualVenueSignupPage';
import VirtualVenueSubscribePage from 'components/pages/VirtualVenueSubscribePage/VirtualVenueSubscribePage';
import VirtualVenueSuccessPage from 'components/pages/VirtualVenueSuccessPage/VirtualVenueSuccessPage';
import VirtualVenueTryoutPage from 'components/pages/VirtualVenueTryoutPage/VirtualVenueTryoutPage';
import ZoomResponsePage from 'components/pages/ZoomResponsePage';

// You must also edit RouteNameToPath
export const RouteNameToComponent = {
  about: AboutPage,
  account_login: AccountLoginPage,
  account_settings: AccountSettingsPage,
  account_signup: AccountSignupPage,
  account_authorize: OAuthAuthorizePage,
  connect_account: StripeResponsePage,
  connect_zoom: ZoomResponsePage,
  dashboard_contacts: ContactsDashboardPage,
  dashboard_event: EventDashboardPage,
  dashboard_events: EventsDashboardPage,
  email_token: AccountTokenLoginPage,
  event_calendar: EventCalendarPage,
  event_create: EventCreatePage,
  event_duplicate: EventDuplicatePage,
  event_read: EventCheckPrivacy,
  event_check_password: EventCheckPrivacy,
  event_proxy: EventLocationProxy,
  event_update: EventUpdatePage,
  event_add_calendar: AddToCalendarPage,
  features_detail_venue: FeaturesDetailVenuePage,
  features_detail: FeaturesDetailPage,
  faq: FaqPage,
  features: FeaturesPage,
  home: IndexPage,
  invite_read: InviteReadPage,
  invite: InviteMagicLinkPage,
  list: AccountContactListsPage,
  listing_edit: ListingEditPage,
  listing_embed: EmbeddedListingPage,
  listing_index: ListingIndexPage,
  listing_read: ListingPage,
  lists: AccountContactListsPage,
  login_as: AccountLoginAsPage,
  order_read: OrderReadPage,
  pricing: SiteFeeCalculator,
  resend_confirmation: ResendConfirmationEmailPage,
  reset_password_token: ResetPasswordTokenPage,
  reset_password: ResetPasswordPage,
  run_read: RunReadPage,
  site_privacy: SitePrivacyPage,
  site_support: SiteSupportPage,
  site: SitePage,
  size_zoom_support: SiteZoomSupportPage,
  ticketing_embed: EmbeddedTicketPage,
  verify_email: AccountVerifyEmailPage,
  venue: VenuePage,
  venue_embed: EmbeddedVenuePage,
  virtual_venue_create: VirtualVenueCreatePage,
  virtual_venue_detail: VirtualVenueDetailsPage,
  virtual_venue_demo: VirtualVenueTryoutPage,
  virtual_venue_edit: VirtualVenueEditPage,
  virtual_venue_guest_log: VirtualVenueGuestLogPage,
  virtual_venue_landing_page: VirtualVenueLandingPage,
  virtual_venue_landing_page2: VirtualVenueLandingPage,
  virtual_venue_list: VirtualVenueListPage,
  virtual_venue_plans: VirtualVenuePlansPage,
  virtual_venue_signup: VirtualVenueSignupPage,
  virtual_venue_recording: VirtualVenueRecordingPage,
  virtual_venue_recordings: VirtualVenueRecordingsPage,
  virtual_venue_chat_history: VirtualVenueChatHistoryPage,
  virtual_venue_subscribe: VirtualVenueSubscribePage,
  virtual_venue_success: VirtualVenueSuccessPage,
  virtual_venue_attend: VirtualVenuePage,
  virtual_venue_embed: EmbeddedVirtualVenuePage,
};

export const ExtraFrontendRoutes = {
  '/account/login/email-token(/)': Redirect('account_login'),
  '/event(/)': Redirect('event_calendar'),
  '/test': TestPage,

  // Fix for wrong URL given to Manual Cinema. Can remove after a while.
  '/feature/:id': (props: { id: string }) => Redirect('features_detail', props)(),
};
