import {
  CREATE_SUBSCRIPTION_ORDER_MUTATION,
  REFRESH_SUBSCRIPTION_ORDER_MUTATION,
  SUBSCRIPTION_ORDER_QUERY,
} from 'utils/gql';
import { CreateSubscriptionOrder, CreateSubscriptionOrderVariables } from 'generated/CreateSubscriptionOrder';
import { Link, Loading } from 'components/common';
import { RefreshSubscriptionOrder, RefreshSubscriptionOrderVariables } from 'generated/RefreshSubscriptionOrder';
import { SubscriptionOrder, SubscriptionOrderVariables } from 'generated/SubscriptionOrder';
import { SubscriptionOrderStatus } from 'generated/globalTypes';
import {
  SubscriptionPlansByType,
  SubscriptionType,
  VirtualVenueCommunityPlan,
  VirtualVenueIndividualPlan,
} from 'shared/shared/constants';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { getURLParam, popUrlParam } from 'utils/urls';
import { reverse } from 'shared/shared/routing/mixily-routes';
import { useMutation, useQuery } from '@apollo/client';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import Button2 from 'components/common/Button2/Button2';
import LoadStripeAndRedirect from 'components/pages/EventReadPage/RsvpForm/TicketingForm/InnerTicketingForm/LoadStripeAndRedirect/LoadStripeAndRedirect';
import LoginRequired from 'components/LoginRequired';
import React, { ReactNode, useEffect, useState } from 'react';
import RedirectWithMessage from 'components/RedirectWithMessage';
import config from 'config';

interface Props {
  id?: string;
}

interface Subscription {
  price: number;
  priceId: string;
}

const getSubscription = (type: string): Subscription => {
  const plan = SubscriptionPlansByType[type];
  // notice we're more careful because type is a url param
  if (plan?.type === SubscriptionType.COMMUNITY) {
    return {
      priceId: config.stripeVirtualVenueCommunityPriceId,
      price: VirtualVenueCommunityPlan.price,
    };
  }
  return {
    priceId: config.stripeVirtualVenueIndividualPriceId,
    price: VirtualVenueIndividualPlan.price,
  };
};

export const VirtualVenueSubscribePage = ({ id }: Props) => {
  const plan = getURLParam('plan', '') ?? '';
  const [reloading, setReloading] = useState<boolean>(false);
  const { data, error } = useQuery<SubscriptionOrder, SubscriptionOrderVariables>(SUBSCRIPTION_ORDER_QUERY, {
    variables: { id: id! },
    skip: !id,
  });
  const order = data?.subscriptionOrder;
  const subscription = getSubscription(plan);

  const [doRefresh, { loading: refreshLoading }] = useMutation<
    RefreshSubscriptionOrder,
    RefreshSubscriptionOrderVariables
  >(REFRESH_SUBSCRIPTION_ORDER_MUTATION, { refetchQueries: [{ query: SUBSCRIPTION_ORDER_QUERY, variables: { id } }] });

  const [doCreate, { data: createData, error: createError, loading: createLoading }] = useMutation<
    CreateSubscriptionOrder,
    CreateSubscriptionOrderVariables
  >(CREATE_SUBSCRIPTION_ORDER_MUTATION);

  const sessionId = createData?.createSubscriptionOrder.stripeCheckoutSessionId;
  const loading = createLoading || refreshLoading || reloading;

  useEffect(() => {
    const errors = createData?.createSubscriptionOrder?.errors;
    if (createData?.createSubscriptionOrder.ok === false && errors && errors.length) {
      addSnackbarMessage(errors[0].message, 'error');
    }
  });

  useEffect(() => {
    if (order?.status === SubscriptionOrderStatus.A) {
      setReloading(false);
    }
  }, [order]);

  // Mismatch between Stripe redirect success and server value Either local dev,
  // or the server failed to process the webhook.
  if (order?.status === SubscriptionOrderStatus.I && popUrlParam('success') === '1') {
    doRefresh({ variables: { id: order.id } }).then(() => {
      setReloading(true);
    });
  }

  if (sessionId) {
    return <LoadStripeAndRedirect stripeCheckoutSessionId={sessionId} />;
  }

  let pageContent: ReactNode | undefined;
  if (loading) {
    pageContent = <Loading />;
  } else if (createError || error) {
    pageContent = (
      <div>
        <p>Sorry, an error ocurred processing your subscription order.</p>
        <p>
          <Link href="/">Return to the home page</Link>.
        </p>
      </div>
    );
  } else if (id) {
    if (order && order.status === SubscriptionOrderStatus.A) {
      pageContent = (
        <RedirectWithMessage
          path={reverse('virtual_venue_success')}
          message={'You have successfully activated your Virtual Venue subscription!'}
        />
      );
    } else if (order && order.status === SubscriptionOrderStatus.I) {
      pageContent = (
        <p>
          Payment failed or cancelled! <Link href={reverse('virtual_venue_plans')}>Click here</Link> to try again.
        </p>
      );
    } else {
      pageContent = <p>Order Not Found!</p>;
    }
  } else {
    pageContent = (
      <div>
        <p className="max-w-sm mb-4">
          Get started with a Virtual Venue Premium plan to access advanced features. All subscriptions start with a 14
          day free trial. No commitment—cancel any time from your account settings page.
        </p>
        <Button2
          onClick={() =>
            doCreate({
              variables: {
                priceId: subscription.priceId,
                successUrlRoute: 'virtual_venue_subscribe',
              },
            })
          }
          className="mt-4"
        >
          Subscribe for ${subscription.price}/mo
        </Button2>
      </div>
    );
  }

  return (
    <LoginRequired>
      <BaseLayout title="Subscribe">{pageContent}</BaseLayout>
    </LoginRequired>
  );
};

export default VirtualVenueSubscribePage;
