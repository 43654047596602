import { FeatureValue } from 'components/pages/VirtualVenuePlansPage/constants';
import IconSvg from 'components/common/IconSvg/IconSvg';
import React from 'react';
import classNames from 'classnames';

interface Props {
  value: FeatureValue;
  iconClass?: string;
  txtClass?: string;
}

const FeaturePresence = ({ value, iconClass, txtClass }: Props) => {
  return value === true ? (
    <IconSvg name="check" className={classNames(iconClass, 'h-5 w-5 text-green-500')} aria-hidden={true} />
  ) : value === false ? (
    <IconSvg name="minus" className={classNames(iconClass, 'h-5 w-5 text-gray-400')} aria-hidden={true} />
  ) : (
    <span className={classNames(txtClass, 'block text-sm leading-5 text-gray-700')}>{value}</span>
  );
};

export default FeaturePresence;
