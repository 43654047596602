import { Loading } from 'components/common';
import { VirtualVenuesByOwner_virtualVenuesByOwner } from 'generated/VirtualVenuesByOwner';
import { reverse } from 'shared/shared/routing/mixily-routes';
import Button2 from 'components/common/Button2/Button2';
import FeatureLockedContainer from 'components/pages/VirtualVenueCreatePage/FeatureLockedContainer';
import IconSvg from 'components/common/IconSvg/IconSvg';
import Link from 'components/Link';
import React from 'react';
import VirtualVenuePreview from 'components/VirtualVenuePreview/VirtualVenuePreview';
import useCanCreateVenue from 'utils/hooks/subscriptions/useCanCreateVenue';

interface Props {
  virtualVenues: VirtualVenuesByOwner_virtualVenuesByOwner[];
}

const VirtualVenueList = ({ virtualVenues }: Props) => {
  const { canCreateVenue, loading } = useCanCreateVenue();
  return (
    <div>
      {virtualVenues.map((v, i) => {
        return (
          <div key={v.id}>
            <div className="mt-24">
              <div className="text-3xl">
                <Link href={reverse('virtual_venue_detail', { id: v.id })} className="text-black hover:no-underline">
                  {v.title}
                </Link>
              </div>
              <div className="max-w-xl">
                <div className="mt-2">
                  <Link href={reverse('virtual_venue_detail', { id: v.id })}>
                    <VirtualVenuePreview virtualVenue={v} />
                  </Link>
                </div>
                <div className="block mt-4 sm:flex sm:flex-row">
                  <Link
                    href={reverse('virtual_venue_edit', { id: v.id })}
                    className="w-full hover:no-underline sm:w-auto"
                  >
                    <Button2>Edit</Button2>
                  </Link>
                  <Link
                    href={reverse('virtual_venue_detail', { id: v.id })}
                    className="block w-full mt-3 sm:mt-0 sm:ml-3 hover:no-underline sm:w-auto"
                  >
                    <Button2>Install</Button2>
                  </Link>
                  <Link
                    href={reverse('virtual_venue_attend', { slugOrId: v.id })}
                    className="block w-full mt-3 sm:mt-0 hover:no-underline sm:w-auto sm:order-3 sm:ml-auto"
                  >
                    <Button2 color="secondary">Attend on mixily.com</Button2>
                  </Link>
                </div>
                <div className="flex items-center mt-4">
                  <IconSvg name="chat" className="w-6 h-6 mr-1 text-link-color" />
                  <Link href={reverse('virtual_venue_chat_history', { id: v.id })}>Chat History</Link>
                  <span className="mx-2 text-link-color">&middot;</span>
                  <IconSvg name="users" className="flex items-center justify-center w-6 h-6 mr-1 text-link-color" />
                  <Link href={reverse('virtual_venue_guest_log', { id: v.id })}>Guest Log</Link>
                  {v.myAccess.ableToRecord && (
                    <>
                      <span className="mx-2 text-link-color">&middot;</span>
                      <IconSvg
                        name="startRecording"
                        className="flex items-center justify-center w-6 h-6 mr-1 text-link-color"
                      />
                      <Link href={reverse('virtual_venue_recordings', { id: v.id })}>Recordings</Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="mt-24">
        <FeatureLockedContainer
          locked={!(canCreateVenue || loading)}
          message={'You have reached your limit of virtual venues.'}
          cta={'Upgrade your plan to get more!'}
        >
          <Link href={reverse('virtual_venue_create')} className="hover:no-underline">
            <Button2 disabled={!canCreateVenue}>
              {loading ? (
                <Loading />
              ) : (
                <>
                  <IconSvg name="plus" className="w-6 h-6 mr-2" /> New Venue
                </>
              )}
            </Button2>
          </Link>
        </FeatureLockedContainer>
      </div>
    </div>
  );
};

export default VirtualVenueList;
