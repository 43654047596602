import Container from 'components/layout/Container/Container';
import React, { ReactNode } from 'react';

import book from './book.svg';
import computer from './computer.svg';
import hands from './hands.svg';
import masks from './masks.svg';
import paper from './paper.svg';
import people from './people.svg';

interface ItemProps {
  iconSrc: string;
  title: string;
  copy: ReactNode;
}

const Item = (props: ItemProps) => {
  return (
    <div className="text-center md:text-left">
      <div className="flex flex-col items-center justify-start md:flex-row">
        <img src={props.iconSrc} className="w-20 h-20" alt="" />
        <div className="text-xs font-bold sm:text-sm md:text-base" style={{ color: '#EF9071' }}>
          {props.title}
        </div>
      </div>
      <div className="mt-4 text-sm md:-mt-1 md:ml-20 lg:-mt-4 md:text-base">{props.copy}</div>
    </div>
  );
};

const UseCases = () => {
  return (
    <div className="py-40 text-center">
      <Container>
        <div className="max-w-md mx-auto text-3xl font-bold text-center md:text-5xl lg:max-w-none">
          A home for your digital community
        </div>
        <div className="grid max-w-screen-lg grid-cols-2 mx-auto mt-20 lg:mt-24 md:grid-cols-3 gap-x-4 gap-y-12 md:gap-y-16 md:gap-x-16">
          <Item
            iconSrc={computer}
            title="Virtual coworking"
            copy="Work together in a unique space that feels different"
          />
          <Item
            iconSrc={book}
            title="Book readings"
            copy={<span>Unite your listeners in a custom designed reading room</span>}
          />
          <Item
            iconSrc={paper}
            title="Coaching"
            copy="Teach in a reocurring virtual space where your clients know to find you"
          />
          <Item
            iconSrc={masks}
            title="Performing arts"
            copy={
              <span>
                Design your theater for
                <br />
                an elevated audience experience
              </span>
            }
          />
          <Item
            iconSrc={people}
            title="Membership communities"
            copy="Plan game nights, mixers, yoga classes, or speaker series"
          />
          <Item iconSrc={hands} title="Team meetings" copy="Use one easy-to-access team meeting room" />
        </div>{' '}
        <div className="h-0 mt-4">&nbsp;</div>
      </Container>
    </div>
  );
};

export default UseCases;
