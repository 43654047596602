import { VirtualVenueCommunityPlan, VirtualVenueFreePlan, VirtualVenueIndividualPlan } from 'shared/shared/constants';
import { constructQueryString } from 'shared/shared/utils';
import { reverse } from 'shared/shared/routing/mixily-routes';

export enum Plans {
  FREE = 'Starter',
  INDIVIDUAL = 'Individual',
  COMMUNITY = 'Community',
}

export enum PlanTypes {
  FREE = 'starter',
  INDIVIDUAL = 'individual',
  COMMUNITY = 'community',
}

export interface PlanWithInfo {
  name: Plans;
  type: PlanTypes;
  description: string;
  price: number | string;
  url: string;
}

export const PLANS: PlanWithInfo[] = [
  {
    name: Plans.FREE,
    type: PlanTypes.FREE,
    description: 'Our full-featured free tier lets you host one customized virtual venue for up to 10 people.',
    price: 'Free',
    url: reverse('virtual_venue_signup'),
  },
  {
    name: Plans.INDIVIDUAL,
    type: PlanTypes.INDIVIDUAL,
    description: 'More rooms, more participants and more features to meet your needs.',
    price: 30,
    url: `${reverse('virtual_venue_signup')}${constructQueryString({
      next: reverse('virtual_venue_subscribe'),
      plan: PlanTypes.INDIVIDUAL,
    })}`,
  },
  {
    name: Plans.COMMUNITY,
    type: PlanTypes.COMMUNITY,
    description: 'More rooms, up to 200 participants and your own dedicated professional streaming platform.',
    price: 100,
    url: `${reverse('virtual_venue_signup')}${constructQueryString({
      next: reverse('virtual_venue_subscribe'),
      plan: PlanTypes.COMMUNITY,
    })}`,
  },
];

// Checkmark, minus sign, or custom text
export type FeatureValue = boolean | string;

export const FEATURE_BUCKETS = ['Basics'] as const;
export type FeatureBucketName = typeof FEATURE_BUCKETS[number];

// type FeaturePresence = Record<PlanName, FeatureValue>;
type FeaturePresence = [FeatureValue, FeatureValue, FeatureValue];

// Map from feature name/description to presence by plan
// type Feature = Record<string, FeaturePresence>;
type Features = Record<string, FeaturePresence>;

export const FEATURES_BY_BUCKET: Record<FeatureBucketName, Features> = {
  Basics: {
    Rooms: [
      String(VirtualVenueFreePlan.features.maxVenues),
      String(VirtualVenueIndividualPlan.features.maxVenues),
      String(VirtualVenueCommunityPlan.features.maxVenues),
    ],
    Participants: [
      String(VirtualVenueFreePlan.features.maxParticipants),
      String(VirtualVenueIndividualPlan.features.maxParticipants),
      String(VirtualVenueCommunityPlan.features.maxParticipants),
    ],
    'Call Length': ['1 hour', 'Unlimited', 'Unlimited'],
    'Customize & Embed': [true, true, true],
    'Chat Room w/ Emoji reactions': [true, true, true],
    'Screen Sharing': [true, true, true],
    'Speaker View': [true, true, true],
    'Host can mute participants': [true, true, true],
    Ticketing: ['Standard fees', 'Standard fees', 'Standard fees'],
  },
};
