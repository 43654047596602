// Based on https://tailwindui.com/components/marketing/page-examples/pricing-pages#component-5f3268c0a01e789d4578d1f0f505594e

import {
  FEATURES_BY_BUCKET,
  FEATURE_BUCKETS,
  PLANS,
  PlanTypes,
  PlanWithInfo,
  Plans,
} from 'components/pages/VirtualVenuePlansPage/constants';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import FeaturePresence from 'components/pages/VirtualVenuePlansPage/FeaturePresence';
import MinimalLayout from 'components/layout/MinimalLayout/MinimalLayout';
import React, { useState } from 'react';
import SubscribeButton from 'components/pages/VirtualVenuePlansPage/SubscribeButton';
import classNames from 'classnames';

const priceToShow = (plan: PlanWithInfo) => {
  if (typeof plan.price === 'number') {
    return (
      <>
        <span className="text-4xl font-extrabold leading-10 text-gray-900">${plan.price}</span>
        <span className="text-base font-semibold leading-6 text-gray-500">/mo</span>
      </>
    );
  }
  return <span className="text-4xl font-extrabold leading-10 text-gray-900">{plan.price}</span>;
};

const subscribeButtonToShow = (plan: PlanWithInfo) => {
  if (plan.name === Plans.FREE) {
    return <SubscribeButton planName={plan.name} href={plan.url} className="mt-6" label="Create an Account" />;
  }
  return <SubscribeButton planName={plan.name} href={plan.url} className="mt-6" />;
};

const radioButtonToShow = (plan: PlanWithInfo, checked: boolean) => {
  if (plan.name === Plans.FREE) {
    return (
      <FormControlLabel value={PlanTypes.FREE} checked={checked} control={<Radio color="primary" />} label="Free" />
    );
  }
  return <FormControlLabel value={plan.type} checked={checked} control={<Radio color="primary" />} label={plan.name} />;
};

const VirtualVenueSubscribePage = () => {
  const [selectedPlan, setSelectedPlan] = useState<PlanTypes>(PlanTypes.FREE);
  function handlePlanChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newType = e.target.value as PlanTypes;
    setSelectedPlan(newType);
  }
  // Use MinimalLayout because responsive styles are janky with sidebar open
  return (
    <MinimalLayout title="Subscribe">
      <div className="bg-white shadow-lg">
        <div className="bg-indigo-900">
          <div className="max-w-2xl px-4 py-16 mx-auto sm:py-24 sm:px-6 lg:px-8 lg:max-w-screen-xl">
            <div className="px-0 sm:px-4 lg:px-0 lg:flex lg:justify-between lg:items-center">
              <div className="max-w-xl">
                <h2 className="text-4xl font-extrabold leading-10 text-white sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">
                  Virtual Venue Pricing
                </h2>
                <p className="mt-5 text-xl leading-7 text-indigo-300">
                  Start hosting for free, then add a site plan to go live. Account plans unlock additional features.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Comparison table */}
        <div className="max-w-2xl py-16 mx-auto bg-white sm:py-24 sm:px-6 lg:max-w-screen-xl lg:px-8">
          <RadioGroup name="type" value="free" onChange={handlePlanChange}>
            {/* xs to lg */}
            <div className="lg:hidden">
              {PLANS.map((plan, planNumber) => (
                <div
                  key={plan.name}
                  className={classNames(planNumber && 'mt-12 pt-12 border-solid border-gray-200 border-t-4')}
                >
                  <div className="px-4">
                    <h2 className="text-lg font-semibold leading-6 text-gray-900">{plan.name}</h2>
                    <p className="mt-4">{priceToShow(plan)}</p>
                    <p className="mt-4 text-sm leading-5 text-gray-500">{plan.description}</p>
                    <div className="mt-6 ">
                      {radioButtonToShow(plan, plan.type === selectedPlan)}
                      {subscribeButtonToShow(plan)}
                    </div>
                  </div>
                  {FEATURE_BUCKETS.map((bucketName, i) => (
                    <table className={classNames(!i && 'mt-8', 'w-full')} key={bucketName}>
                      <caption className="px-4 py-3 text-sm font-semibold leading-5 text-left text-gray-900 bg-gray-100 border-t border-gray-200 border-solid">
                        {bucketName}
                      </caption>
                      <tbody className="divide-y divide-gray-200">
                        {Object.entries(FEATURES_BY_BUCKET[bucketName]).map(([featureName, featureValues]) => (
                          <tr className="border-t border-gray-200 border-solid" key={featureName}>
                            <th className="px-4 py-5 text-sm font-normal leading-5 text-left text-gray-800" scope="row">
                              {featureName}
                            </th>
                            <td className="py-5 pr-4">
                              <FeaturePresence
                                value={featureValues[planNumber]}
                                txtClass="text-right"
                                iconClass="ml-auto"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ))}
                  <div className="hidden px-4 pt-5 border-t border-gray-200 border-solid">
                    {subscribeButtonToShow(plan)}
                  </div>
                </div>
              ))}
            </div>
            {/* lg+ */}
            <div className="hidden lg:block">
              <table className="w-full h-px table-fixed">
                <caption className="sr-only">Pricing plan comparison</caption>
                <thead>
                  <tr className="border-solid">
                    <th className="pb-4 pl-6 pr-6 text-sm font-semibold leading-5 text-left text-gray-900" scope="col">
                      <span>Plans</span>
                    </th>
                    {PLANS.map((plan) => (
                      <th
                        className="w-1/4 px-6 pb-4 text-lg font-semibold leading-6 text-left text-gray-900"
                        scope="col"
                        key={plan.name}
                      >
                        {plan.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="border-t border-gray-200 border-solid divide-y divide-gray-200">
                  <tr className="border-solid">
                    <th
                      className="py-8 pl-6 pr-6 text-sm font-semibold leading-5 text-left text-gray-900 align-top"
                      scope="row"
                    >
                      Pricing
                    </th>
                    {PLANS.map((plan) => (
                      <td className="h-full px-6 py-8 align-top" key={plan.name}>
                        <div className="flex flex-col justify-between h-full">
                          <div>
                            <p>{priceToShow(plan)}</p>
                            <p className="mt-4 text-sm leading-5 text-gray-500">{plan.description}</p>
                          </div>
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr className="border-solid">
                    <th
                      className="py-4 pl-6 pr-6 text-sm font-semibold leading-5 text-left text-gray-900 align-top"
                      scope="row"
                    ></th>
                    {PLANS.map((plan) => (
                      <td className="h-full px-6 py-4 align-middle" key={plan.type}>
                        <div className="flex flex-col justify-center h-full">
                          <FormControlLabel
                            value={plan.type}
                            checked={selectedPlan === plan.type}
                            control={<Radio color="primary" />}
                            label={plan.name}
                          />
                        </div>
                      </td>
                    ))}
                    <td className="h-full px-6 py-4 align-middle" key="continue">
                      <div className="flex flex-col justify-center h-full">
                        <SubscribeButton
                          planName={selectedPlan}
                          href={PLANS.find((p) => p.type === selectedPlan)?.url ?? '#'}
                          label={'Continue'}
                        />
                      </div>
                    </td>
                  </tr>
                  {FEATURE_BUCKETS.map((bucketName) => (
                    <>
                      <tr className="border-solid">
                        <th
                          className="py-3 pl-6 text-sm font-semibold leading-5 text-left text-gray-900 bg-gray-100"
                          colSpan={4}
                          scope="colgroup"
                        >
                          {bucketName}
                        </th>
                      </tr>
                      {Object.keys(FEATURES_BY_BUCKET[bucketName]).map((featureName) => (
                        <tr key={featureName} className="border-solid">
                          <th
                            className="py-5 pl-6 pr-6 text-sm font-normal leading-5 text-left text-gray-800"
                            scope="row"
                          >
                            {featureName}
                          </th>
                          {FEATURES_BY_BUCKET[bucketName][featureName].map((featureValue, i) => (
                            <td className="px-6 py-5" key={i}>
                              <FeaturePresence value={featureValue} />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </>
                  ))}
                </tbody>
                <tfoot className="hidden">
                  <tr className="border-t border-gray-200 border-solid">
                    <th className="sr-only" scope="row">
                      Choose your plan
                    </th>
                    {PLANS.map((plan, i) => (
                      <td key={i} className="px-6 pt-5">
                        {subscribeButtonToShow(plan)}
                      </td>
                    ))}
                  </tr>
                </tfoot>
              </table>
            </div>
          </RadioGroup>
        </div>
      </div>
    </MinimalLayout>
  );
};

export default VirtualVenueSubscribePage;
