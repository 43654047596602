import { Link } from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';

interface Props {
  planName: string;
  href: string;
  label?: string;
  className?: string;
}

const SubscribeButton = ({ planName, className, label, href }: Props) => {
  const cls = classNames(
    'w-full bg-gradient-to-r from-orange-500 to-pink-500 border border-transparent rounded-md shadow py-2 text-sm leading-5 font-semibold text-white hover:to-pink-600 focus:outline-none focus:ring-gray transition duration-150 ease-in-out',
    className
  );
  return (
    <Link href={href}>
      <button type="button" className={cls}>
        {label ? label : `Buy ${planName}`}
      </button>
    </Link>
  );
};

export default SubscribeButton;
