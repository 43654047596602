import { Button, Link } from 'components/common';
import { reverse } from 'shared/shared/routing/mixily-routes';
import Examples3 from 'components/pages/VirtualVenueLandingPage/LandingPage/Examples/Examples3';
import Features3 from 'components/pages/VirtualVenueLandingPage/LandingPage/Features/Features3';
import Footer3 from 'components/pages/VirtualVenueLandingPage/LandingPage/Footer/Footer3';
import IntroSection3 from 'components/pages/VirtualVenueLandingPage/LandingPage/IntroSection/IntroSection3';
import KeyBenefits3 from 'components/pages/VirtualVenueLandingPage/LandingPage/KeyBenefits/KeyBenefits3';
import PricingTable from 'components/pages/VirtualVenueLandingPage/LandingPage/Footer/PricingTable';
import React from 'react';
import Testimonials3 from 'components/pages/VirtualVenueLandingPage/LandingPage/Testimonials/Testimonials3';
import UseCases from './UseCases/UseCases';

const Landing3 = () => {
  return (
    <div>
      <IntroSection3 />

      <KeyBenefits3 />

      <div className="mt-12 md:mt-24 lg:mt-32">
        <Examples3 />
      </div>

      <div className="mt-32 text-center lg:mt-48">
        <div>
          <Button
            color="custom"
            href={reverse('virtual_venue_plans')}
            className="px-6 py-2 text-xl font-extrabold text-white uppercase rounded-md bg-default focus:outline-none md:text-2xl lg:text-3xl "
            label="Sign Up for free"
          />
        </div>
        <div className="mt-4">
          <Link href={reverse('virtual_venue_demo')} className="text-lg text-black underline">
            or try the theme editor
          </Link>
        </div>
      </div>

      <div className="mt-32 lg:mt-36">
        <Testimonials3 />
      </div>

      <div className="mt-40">
        <PricingTable />
      </div>

      <div className="mt-40">
        <UseCases />
      </div>

      <Features3 />

      <div className="mt-20 text-center lg:mt-32">
        <div>
          <Button
            color="custom"
            href={reverse('virtual_venue_plans')}
            className="px-6 py-2 text-xl font-extrabold text-white uppercase rounded-md bg-default focus:outline-none md:text-2xl lg:text-3xl "
            label="Sign Up for free"
          />
        </div>
        <div className="mt-4">
          <Link href={reverse('virtual_venue_demo')} className="text-lg text-black underline">
            or try the theme editor
          </Link>
        </div>
      </div>

      <div className="mt-36">
        <Footer3 />
      </div>
    </div>
  );
};

export default Landing3;
