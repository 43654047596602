import { reverse } from 'shared/shared/routing/mixily-routes';
import Link from 'components/Link';
import React, { ReactNode } from 'react';

interface Props {
  locked?: boolean;
  message?: string;
  cta: string;
  children: ReactNode;
}

const FeatureLockedContainer = (props: Props) => {
  const { message, children, locked, cta } = props;
  return !locked ? (
    <>{children}</>
  ) : (
    <div className="inline-flex p-4 border-l-4 border-yellow-400 border-solid" style={{ backgroundColor: '#faf5ea' }}>
      <div className="flex">
        <div className="flex-shrink-0">
          <svg
            className="w-8 h-8 text-yellow-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          <p className="text-xl text-yellow-700">
            {message}{' '}
            <Link
              href={reverse('virtual_venue_plans')}
              className="font-medium text-yellow-700 underline hover:text-yellow-600"
            >
              {cta}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
export default FeatureLockedContainer;
